// Top Nav Reducer
export const UPDATE_EDIT_MODE = 'UPDATE_EDIT_MODE';
export const UPDATE_SHOW_MORE = 'UPDATE_SHOW_MORE';
export const UPDATE_TOP_NAV_HEIGHT = 'UPDATE_TOP_NAV_HEIGHT';
export const UPDATE_SIDEBAR_VISIBLE = 'UPDATE_SIDEBAR_VISIBLE';
export const UPDATE_IS_AUTO_SAVING = 'UPDATE_IS_AUTO_SAVING';
export const TOOGLE_TOOLTIPS = 'TOGGLE_TOOLTIPS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const IS_MEP_NAME_EDITABLE = 'IS_MEP_NAME_EDITABLE';

// My Home //
export const UPDATE_TRANSLATION_COMPLETION_STATUS = 'UPDATE_TRANSLATION_COMPLETION_STATUS;';
export const TRANSLATED_PACKAGE_NAME = 'TRANSLATED_PACKAGE_NAME';
export const UPDATE_PUBLISHED_MEP_DELETION_STATUS = 'UPDATE_PUBLISHED_MEP_DELETION_STATUS';
export const DELETED_PUBLISHED_PACKAGE_NAME = 'DELETED_PUBLISHED_PACKAGE_NAME';
export const DELETED_UNPUBLISHED_PACKAGE_NAME = 'DELETED_UNPUBLISHED_PACKAGE_NAME';
export const REFRESH_PACKAGES = 'REFRESH_PACKAGES';
export const MY_HOME_LOADER_ACTIVE = 'MY_HOME_LOADER_ACTIVE';
export const SET_IS_VIEW_PUBLISHED_MEP_MESSAGE_OPEN = 'SET_IS_VIEW_PUBLISHED_MEP_MESSAGE_OPEN';

// Upload Modal //
export const UPDATE_UPLOAD_MODAL_OPEN = 'UPDATE_UPLOAD_MODAL_OPEN';
export const UPDATE_UPLOAD_BUTTON_DISABLED = 'UPDATE_UPLOAD_BUTTON_DISABLED';
export const UPDATE_UPLOAD_MESSAGE_STATE = 'UPDATE_UPLOAD_MESSAGE_STATE';
export const UPDATE_UPLOAD_WORKFLOW = 'UPDATE_UPLOAD_WORKFLOW';
export const UPDATE_IS_VALID_FILE_TYPE = 'UPDATE_VALID_FILE_TYPE';

export const PLANNING_INFO_BANNER_SHOW_LESS = 'PLANNING_INFO_BANNER_SHOW_LESS';
export const MAPPING_INFO_BANNER_SHOW_LESS = 'MAPPING_INFO_BANNER_SHOW_LESS';

// Analyze Requirements //
export const ANALYZE_INFO_BANNER_SHOW_LESS = 'ANALYZE_INFO_BANNER_SHOW_LESS';
export const CUSTOM_MAPPING_SPREADSHEET_OPEN = 'CUSTOM_MAPPING_SPREADSHEET_OPEN';

// Api Error Reducer
export const SET_SYSTEM_ERROR_OCCURRED = 'SET_SYSTEM_ERROR_OCCURRED';
export const UPDATE_API_ERROR_DETAILS = 'UPDATE_API_ERROR_DETAILS';
export const UPDATE_API_ERROR_DETAILS_MODAL_OPEN = 'UPDATE_API_ERROR_DETAILS_MODAL_OPEN';
export const UPDATE_SHOW_API_ERROR_NOTIFICATION = 'UPDATE_SHOW_API_ERROR_NOTIFICATION';
export const RESET_API_ERROR_DETAILS = 'RESET_API_ERROR_DETAILS';

// Build Validate Reducer
export const BUILD_INFO_BANNER_SHOW_LESS = 'BUILD_INFO_BANNER_SHOW_LESS';
export const UPDATE_WANTLIST_EMPTY = 'UPDATE_WANTLIST_EMPTY';
export const UPDATE_SSGT_WANTLIST_ERROR = 'UPDATE_SSGT_WANTLIST_ERROR';
export const UPDATE_SSGT_SUBSET_ERROR = 'UPDATE_SSGT_SUBSET_ERROR';
export const BUILD_UPDATE_TRANSLATE_MESSAGE = 'BUILD_UPDATE_TRANSLATE_MESSAGE';
export const GENERATE_SUBSET_SCHEMA_ACTIVE = 'GENERATE_SUBSET_SCHEMA_ACTIVE';

export const UPDATE_VALIDATION_ARTIFACTS = 'UPDATE_VALIDATION_ARTIFACTS';
export const RESET_VALIDATION_ARTIFACTS = 'RESET_VALIDATION_ARTIFACTS';
export const IS_VALIDATION_DATA_LOADING = 'IS_VALIDATION_DATA_LOADING';
export const SET_SHOW_VALIDATION_RESULTS = 'SET_SHOW_VALIDATION_RESULTS';
export const SET_SHOW_SUBSET_MESSAGE = 'SET_SHOW_SUBSET_MESSAGE';

// Custom Model Extensions (CME) Builder
export const UPDATE_CME_BUILDER_MODAL_OPEN = 'UPDATE_CME_BUILDER_MODAL_OPEN';
export const CME_BUILDER_UPDATE_LIST_OF_CODE_OBJS = 'CME_BUILDER_UPDATE_LIST_OF_CODE_OBJS';
export const CME_BUILDER_UPDATE_LIST_OF_CONTAINER_ELEMENT_OBJS = 'CME_BUILDER_UPDATE_LIST_OF_CONTAINER_ELEMENT_OBJS';
export const UPDATE_CME_BUILDER_FULLSCREEN_MODAL_OPEN = 'UPDATE_CME_BUILDER_FULLSCREEN_MODAL_OPEN';
export const UPDATE_CME_BUILDER_RESET_CODELIST_MODAL_OPEN = 'UPDATE_CME_BUILDER_RESET_CODELIST_MODAL_OPEN';
export const UPDATE_CME_BUILDER_FULLSCREEN_VIEWPORT_TYPE = 'UPDATE_CME_BUILDER_FULLSCREEN_VIEWPORT_TYPE';
export const UPDATE_CME_BUILDER_DATA = 'UPDATE_CME_BUILDER_DATA';
export const UPDATE_CME_BUILDER_DATA_CHILDREN = 'UPDATE_CME_BUILDER_DATA_CHILDREN';
export const UPDATE_CME_BUILDER_ROOT_ELEMENT_NAME = 'UPDATE_CME_BUILDER_ROOT_ELEMENT_NAME';
export const UPDATE_CME_BUILDER_URI = 'UPDATE_CME_BUILDER_URI';
export const UPDATE_CME_BUILDER_DEFINITION = 'UPDATE_CME_BUILDER_DEFINITION';
export const UPDATE_CME_MAJOR_RELEASE_VERSION = 'UPDATE_CME_MAJOR_RELEASE_VERSION'; // used for NDR validation based on MEP release
export const RESET_CME_BUILDER = 'RESET_CME_BUILDER';
// used to mark Extension Schema as complete in ArtifactChecklist.js
export const UPDATE_CME_BUILDER_IS_CME_COMPELTE = 'UPDATE_CME_BUILDER_IS_CME_COMPELTE';
// used to determine failed/successful generation message on BuildValidate page and allow the message to disappear after 5s by resetting the flag to '' but NOT remove completion from the ArtifactChecklist
export const UPDATE_CME_BUILDER_IS_EXTENSION_SCHEMA_GENERATED = 'UPDATE_CME_BUILDER_IS_EXTENSION_SCHEMA_GENERATED';
export const SHOW_CODE_IMPORT_FILE = 'SHOW_CODE_IMPORT_FILE';
export const CODE_IMPORT_FILE_DATA = 'CODE_IMPORT_FILE_DATA';
export const CME_SOURCE_CODE_FILE = 'CME_SOURCE_CODE_FILE';
export const CME_CODE_IMPORT_SUMMARY = 'CME_CODE_IMPORT_SUMMARY';
export const CME_CODE_IMPORT_REPORT_DATA = 'CME_CODE_IMPORT_REPORT_DATA';
// edit/delete functionaility
export const UPDATE_CME_BUILDER_IS_EDIT_MODE = 'UPDATE_CME_BUILDER_IS_EDIT_MODE';
export const UPDATE_CME_BUILDER_EXISTING_FORM_DATA = 'UPDATE_CME_BUILDER_EXISTING_FORM_DATA';
export const UPDATE_CME_BUILDER_EXISTING_CODE_DATA = 'UPDATE_CME_BUILDER_EXISTING_CODE_DATA';
export const UPDATE_CME_BUILDER_EXISTING_CONTAINER_ELEMENT_DATA = 'UPDATE_CME_BUILDER_EXISTING_CONTAINER_ELEMENT_DATA';
export const CME_BUILDER_UPDATE_ELEMENT_CREATION_STATUS = 'CME_BUILDER_UPDATE_ELEMENT_CREATION_STATUS';
export const CME_BUILDER_UPDATE_CHANGES_SAVED_STATUS = 'CME_BUILDER_UPDATE_CHANGES_SAVED_STATUS';
export const CME_BUILDER_UPDATE_ITEM_DELETE_STATUS_OBJ = 'CME_BUILDER_UPDATE_ITEM_DELETE_STATUS_OBJ';

export const BROWSE_AND_READ_ARTIFACT = 'BROWSE_AND_READ_ARTIFACT';
export const RESET_ARTIFACT = 'RESET_ARTIFACT';
export const UPDATE_ARTIFACT_TREE = 'UPDATE_ARTIFACT_TREE';
export const UPDATE_ARTIFACT_NODE = 'UPDATE_ARTIFACT_NODE';
export const UPDATE_ARTIFACT_TAG = 'UPDATE_ARTIFACT_TAG';
export const UPDATE_UPLOAD_MODAL_NODE_ID = 'UPDATE_UPLOAD_MODAL_NODE_ID';
export const CLEAR_ARTIFACT_TREE = 'CLEAR_ARTIFACT_TREE';
export const RERENDER_ARTIFACT_TREE = 'RERENDER_ARTIFACT_TREE';
export const SET_CHANGELOG_NEEDS_REVIEW = 'SET_CHANGELOG_NEEDS_REVIEW';
export const SET_README_NEEDS_REVIEW = 'SET_README_NEEDS_REVIEW';
export const SET_SAMPLE_NEEDS_REVIEW = 'SET_SAMPLE_NEEDS_REVIEW';

// Contact Form Pop-up Modal //
export const CONTACT_MODAL_OPEN = 'CONTACT_MODAL_OPEN';
export const CONTACT_MODAL_SUBMIT = 'CONTACT_MODAL_SUBMIT';
export const CONTACT_MODAL_SUCCESS = 'CONTACT_MODAL_SUCCESS';
export const CONTACT_MODAL_UPDATE_ERROR = 'CONTACT_MODAL_UPDATE_ERROR';

// Header Navigation Active Tabs //
export const GETTING_STARTED_ACTIVE = 'GETTING_STARTED_ACTIVE';
export const MY_HOME_ACTIVE = 'MY_HOME_ACTIVE';
export const PACKAGE_BUILDER_ACTIVE = 'PACKAGE_BUILDER_ACTIVE';
export const STRATEGIC_INITIATIVES_ACTIVE = 'STRATEGIC_INITIATIVES_ACTIVE';
export const COMMUNITIES_ACTIVE = 'COMMUNITIES_ACTIVE';
export const TRAINING_ACTIVE = 'TRAINING_ACTIVE';
export const ADMINISTRATOR_ACTIVE = 'ADMINISTRATOR_ACTIVE';

// Left Navigation Sidebar Reducer //
export const UPDATE_SIDEBAR_WIDTH = 'UPDATE_SIDEBAR_WIDTH';
export const UPDATE_CREATION_GUIDE_ACTIVE = 'UPDATE_CREATION_GUIDE_ACTIVE';
export const UPDATE_SCENARIO_PLANNING_ACTIVE = 'UPDATE_SCENARIO_PLANNING_ACTIVE';
export const UPDATE_ANALYZE_REQUIREMENTS_ACTIVE = 'UPDATE_ANALYZE_REQUIREMENTS_ACTIVE';
export const UPDATE_MAP_MODEL_ACTIVE = 'UPDATE_MAP_MODEL_ACTIVE';
export const UPDATE_BUILD_VALIDATE_ACTIVE = 'UPDATE_BUILD_VALIDATE_ACTIVE';
export const UPDATE_ASSEMBLE_DOCUMENT_ACTIVE = 'UPDATE_ASSEMBLE_DOCUMENT_ACTIVE';
export const UPDATE_PUBLISH_IMPLEMENT_ACTIVE = 'UPDATE_PUBLISH_IMPLEMENT_ACTIVE';

// MPD Catalog Reducer //
export const UPDATE_UNPUBLISHED_PACKAGES_LIST = 'UPDATE_UNPUBLISHED_PACKAGES_LIST';
export const UPDATE_PUBLISHED_PACKAGES_LIST = 'UPDATE_PUBLISHED_PACKAGES_LIST';
export const UPDATE_UNPUBLISHED_SEARCH_RESULTS_LIST = 'UPDATE_UNPUBLISHED_SEARCH_RESULTS_LIST';
export const UPDATE_PUBLISHED_SEARCH_RESULTS_LIST = 'UPDATE_PUBLISHED_SEARCH_RESULTS_LIST';
export const PUBLISHED_PACKAGES_ACTIVE = 'PUBLISHED_PACKAGES_ACTIVE';
export const UPDATE_PACKAGE_OWNER_ID = 'UPDATE_PACKAGE_OWNER_ID';
export const UPDATE_MPD_PACKAGE_ID = 'UPDATE_MPD_PACKAGE_ID';
export const UPDATE_MPD_PACKAGE_NAME = 'UPDATE_MPD_PACKAGE_NAME';
export const UPDATE_MPD_RELEASE = 'UPDATE_MPD_RELEASE';
export const UPDATE_MPD_VERSION = 'UPDATE_MPD_VERSION';
export const UPDATE_MPD_STATUS = 'UPDATE_MPD_STATUS';
export const UPDATE_MPD_STATUS_NO = 'UPDATE_MPD_STATUS_NO';
export const UPDATE_MPD_POC = 'UPDATE_MPD_POC';
export const UPDATE_MPD_EMAIL = 'UPDATE_MPD_EMAIL';
export const UPDATE_MPD_DESCRIPTION = 'UPDATE_MPD_DESCRIPTION';
export const UPDATE_MPD_ORGANIZATION_NAME = 'UPDATE_MPD_ORGANIZATION_NAME';
export const UPDATE_MPD_ORGANIZATION_TYPE = 'UPDATE_MPD_ORGANIZATION_TYPE';
export const UPDATE_MPD_FORMAT = 'UPDATE_MPD_FORMAT';
export const UPDATE_MPD_COI_TAGS = 'UPDATE_COI_TAGS';
export const UPDATE_MPD_EXCHANGE_TAGS = 'UPDATE_EXCHANGE_TAGS';
export const UPDATE_MPD_URI = 'UPDATE_MPD_URI';
export const UPDATE_MPD_CREATION_DATE = 'UPDATE_MPD_CREATION_DATE';
export const UPDATE_MPD_RELEASE_LOCKED = 'UPDATE_MPD_RELEASE_LOCKED';
export const RESET_MPD_CATALOG_FORM = 'RESET_MPD_CATALOG_FORM';
export const UPDATE_IS_REQUIRED_ARTIFACT_UPLOADED = 'UPDATE_IS_REQUIRED_ARTIFACT_UPLOADED';
export const UPDATE_MPD_IS_PUBLISHED = 'UPDATE_MPD_IS_PUBLISHED';
export const UPDATE_MPD_IS_COPIED_PACKAGE = 'UPDATE_MPD_IS_COPIED_PACKAGE';
export const UPDATE_MPD_IS_MIGRATED_PACKAGE = 'UPDATE_MPD_IS_MIGRATED_PACKAGE';

// Mapping Document Reducer //
export const CLEAR_MAPPING_DOC = 'CLEAR_MAPPING_DOC';
export const UPDATE_PROPERTY_SHEET = 'UPDATE_PROPERTY_SHEET';
export const UPDATE_TYPE_SHEET = 'UPDATE_TYPE_SHEET';
export const UPDATE_TYPE_HAS_PROPERTY_SHEET = 'UPDATE_TYPE_HAS_PROPERTY_SHEET';
export const UPDATE_CODES_FACETS_SHEET = 'UPDATE_CODES_FACETS_SHEET';
export const UPDATE_NAMESPACE_SHEET = 'UPDATE_NAMESPACE_SHEET';
export const UPDATE_LOCAL_TERMINOLOGY_SHEET = 'UPDATE_LOCAL_TERMINOLOGY_SHEET';
export const UPDATE_TYPE_UNION_SHEET = 'UPDATE_TYPE_UNION_SHEET';
export const UPDATE_METADATA_SHEET = 'UPDATE_METADATA_SHEET';
export const UPDATE_AUTO_ADDED_TYPE_QNAME = 'UPDATE_AUTO_ADDED_TYPE_QNAME';
export const UPDATE_AUTO_ADDED_PROPERTY_COUNT = 'UPDATE_AUTO_ADDED_PROPERTY_COUNT';
export const UPDATE_AUTO_ADDED_TYPE_COUNT = 'UPDATE_AUTO_ADDED_TYPE_COUNT';

// Session Reducer //
export const UPDATE_STANDALONE = 'UPDATE_STANDALONE';
export const UPDATE_LOGGED_IN = 'UPDATE_LOGGED_IN';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const FORCE_BROWSER_REFRESH = 'FORCE_BROWSER_REFRESH';
export const IS_LOG_OUT_ACTIVE = 'IS_LOG_OUT_ACTIVE';

// Existing MEP Name Modal Reducer //
export const EXISTING_MEP_NAME_MODAL_OPEN = 'EXISTING_MEP_NAME_MODAL_OPEN';
export const UPDATE_OVERWRITE_SAVE = 'UPDATE_OVERWRITE_SAVE';
export const RETURN_HOME_ON_SAVE = 'RETURN_HOME_ON_SAVE';
export const UPDATE_DUPLICATE_PACKAGE_ID = 'UPDATE_DUPLICATE_PACKAGE_ID';

// Existing File Name Modal Reducer //
export const EXISTING_FILE_NAME_MODAL_OPEN = 'EXISTING_FILE_NAME_MODAL_OPEN';

// Log Out Reducer //
export const LOG_OUT_MODAL_OPEN = 'LOG_OUT_MODAL_OPEN';

// SSGT Modal State Reducer
export const UPDATE_SSGT_MAPPING_MODAL_OPEN = 'UPDATE_SSGT_MAPPING_MODAL_OPEN';
export const UPDATE_SSGT_SEARCH_STRING = 'UPDATE_SSGT_SEARCH_STRING';
export const UPDATE_PROPERTY_TO_MAP = 'UPDATE_PROPERTY_TO_MAP';
export const UPDATE_ROW_KEY_TO_MAP = 'UPDATE_ROW_KEY_TO_MAP';
export const UPDATE_SSGT_SEARCH_TYPE = 'UPDATE_SSGT_SEARCH_TYPE';

//User Management Modal Reducer //
export const USER_MANAGEMENT_MODAL_OPEN = 'USER_MANAGEMENT_MODAL_OPEN';

//User Profile Modal Reducer //
export const USER_PROFILE_MODAL_OPEN = 'USER_PROFILE_MODAL_OPEN';

//Request Account Modal Reducer //
export const REQUEST_ACCOUNT_MODAL_OPEN = 'REQUEST_ACCOUNT_MODAL_OPEN';

// Password Expiring Warning Reducer
export const PASSWORD_EXPIRING_MESSAGE_OPEN = 'PASSWORD_EXPIRING_MESSAGE_OPEN';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';

// Reset Password Modal
export const RESET_PASSWORD_MODAL_OPEN = 'RESET_PASSWORD_MODAL_OPEN';
export const RESET_SELECTED_USER = 'RESET_SELECTED_USER';

// Transfer Packages Modal
export const TRANSFER_PACKAGES_MODAL_OPEN = 'TRANSFER_PACKAGES_MODAL_OPEN';
export const UPDATE_PACKAGE_TRANSFER_FROM_USER = 'UPDATE_PACKAGE_TRANSFER_FROM_USER';
export const RESET_TRANSFER_PACKAGES_MODAL = 'RESET_TRANSFER_PACKAGES_MODAL';
export const UPDATE_NUMBER_OF_PACKAGES_TO_TRANSFER = 'UPDATE_NUMBER_OF_PACKAGES_TO_TRANSFER';
export const UPDATE_TRANSFERED_PACKAGES_COUNT = 'UPDATE_TRANSFERED_PACKAGES_COUNT';

// Admin Module Reducer
export const ADMIN_UPDATE_LIST_OF_ALL_USERS = 'ADMIN_UPDATE_LIST_OF_ALL_USERS';
export const ADMIN_UPDATE_LIST_OF_PENDING_USERS = 'ADMIN_UPDATE_LIST_OF_PENDING_USERS';
export const ADMIN_UPDATE_SUCCESS_MESSAGE = 'ADMIN_UPDATE_SUCCESS_MESSAGE';
export const ADMIN_UPDATE_PERMISSION_ERROR = 'ADMIN_UPDATE_PERMISSION_ERROR';
export const ADMIN_UPDATE_DELETE_SELF_ERROR = 'ADMIN_UPDATE_DELETE_SELF_ERROR';
export const ADMIN_UPDATE_DELETE_USER_WITH_PACKAGES_ERROR = 'ADMIN_UPDATE_DELETE_USER_WITH_PACKAGES_ERROR';
export const ADMIN_UPDATE_VALIDATION_ERROR = 'AMIN_UPDATE_VALIDATION_ERROR';
export const ADMIN_UPDATE_EMAIL_EXISTS_ERROR = 'ADMIN_UPDATE_EMAIL_EXISTS_ERROR';
export const ADMIN_UPDATE_FNAME_ERROR = 'ADMIN_UPDATE_FNAME_ERROR';
export const ADMIN_UPDATE_LNAME_ERROR = 'ADMIN_UPDATE_LNAME_ERROR';
export const ADMIN_UPDATE_ORG_ERROR = 'ADMIN_UPDATE_ORG_ERROR';
export const ADMIN_UPDATE_EMAIL_ERROR = 'ADMIN_UPDATE_EMAIL_ERROR';
export const ADMIN_UPDATE_PHONE_ERROR = 'ADMIN_UPDATE_PHONE_ERROR';
export const ADMIN_UPDATE_ROLE_ERROR = 'ADMIN_UPDATE_ROLE_ERROR';
export const ADMIN_MODULE_MODAL_OPEN = 'ADMIN_MODULE_MODAL_OPEN';
export const ADMIN_MODULE_UPDATE_PENDING_USER_DATA = 'ADMIN_MODULE_UPDATE_PENDING_USER_DATA';
export const ADMIN_MODULE_UPDATE_CURRENT_ALL_USER_DATA = 'ADMIN_MODULE_UPDATE_CURRENT_ALL_USER_DATA';
export const ADMIN_MODULE_UPDATE_WORKFLOW = 'ADMIN_MODULE_UPDATE_WORKFLOW';

// Modify Artifact Tree Modal Reducer
export const UPDATE_ITEM_DATA = 'UPDATE_ITEM_DATA';
export const MODIFY_ARTIFACT_TREE_MODAL_OPEN = 'MODIFY_ARTIFACT_TREE_MODAL_OPEN';
export const UPDATE_MODIFY_ARTIFACT_TREE_WORKFLOW = 'UPDATE_MODIFY_ARTIFACT_TREE_WORKFLOW';

// Assemble & Document Reducer
export const ASSEMBLE_INFO_BANNER_SHOW_LESS = 'ASSEMBLE_INFO_BANNER_SHOW_LESS';
export const ASSEMBLE_DOCUMENT_COMPLETE = 'ASSEMBLE_DOCUMENT_COMPLETE';
export const ACTIVE_PANE = 'ACTIVE_PANE';
export const ASSEMBLE_UPLOAD_MESSAGE = 'ASSEMBLE_UPLOAD_MESSAGE';
export const UPDATE_BUSINESS_RULES = 'UPDATE_BUSINESS_RULES';

//Publish & Implement Reducer
export const PUBLISH_INFO_BANNER_SHOW_LESS = 'PUBLISH_INFO_BANNER_SHOW_LESS';
export const PUBLISH_IMPLEMENT_COMPLETE = 'PUBLISH_IMPLEMENT_COMPLETE';
export const PUBLISH_CONGRATS_MODAL_OPEN = 'PUBLISH_CONGRATS_MODAL_OPEN';

// Release Modal Reducer
export const RELEASE_MODAL_OPEN = 'RELEASE_MODAL_OPEN';

// NIEM Data Reducer
export const IS_NIEM_DATA_LOADING = 'IS_NIEM_DATA_LOADING';
export const LOADED_RELEASES = 'LOADED_RELEASES';

// Release Migration Reducer
export const RELEASE_MIGRATION_MODAL_OPEN = 'RELEASE_MIGRATION_MODAL_OPEN';
export const ROW_DATA = 'ROW_DATA';
export const MIGRATED_PACKAGE_ID = 'MIGRATED_PACKAGE_ID';

// Copy MEP Modal via 'My Home'Reducer
export const IS_COPY_MEP_MODAL_OPEN = 'IS_COPY_MEP_MODAL_OPEN';
export const UPDATE_COPIED_PACKAGE_ID = 'UPDATE_COPIED_PACKAGE_ID';

// MEP Change Warning Modal Reducer
export const MEP_CHANGE_WARNING_MODAL_OPEN = 'MEP_CHANGE_WARNING_MODAL';
export const MEP_CONTAINS_DEFAULT_TEXT_TRUE = 'MEP_CONTAINS_DEFAULT_TEXT_TRUE';
export const MEP_CONTAINS_SUBSET_TEXT_TRUE = 'MEP_CONTAINS_SUBSET_TEXT_TRUE';
export const MEP_CONTAINS_SUBSET_TRANSLATION_TEXT_TRUE = 'MEP_CONTAINS_SUBSET_TRANSLATION_TEXT_TRUE';
export const MEP_CONTAINS_TRANSLATION_TEXT_TRUE = 'MEP_CONTAINS_TRANSLATION_TEXT_TRUE';
export const GENERATE_SUBSET_TEXT_TRUE = 'GENERATE_SUBSET_TEXT_TRUE';
export const GENERATE_SUBSET_TRANSLATION_TEXT_TRUE = 'GENERATE_SUBSET_TRANSLATION_TEXT_TRUE';
export const GENERATE_TRANSLATION_TEXT_TRUE = 'GENERATE_TRANSLATION_TEXT_TRUE';
export const MEP_CHANGE_WARNING_MODAL_TRIGGER = 'MEP_CHANGE_WARNING_MODAL_TRIGGER';

// Translate Reducer
export const GENERATE_TRANSLATION_ACTIVE = 'GENERATE_TRANSLATION_ACTIVE';
export const IS_TRANSLATION_GENERATED = 'IS_TRANSLATION_GENERATED';
export const UPDATE_INDEX_FOR_TRANSLATION_GENERATION = 'UPDATE_INDEX_FOR_TRANSLATION_GENERATION';

// Sample Options Modal Reducer
export const SET_SHOW_SAMPLE_OPTIONS_MODAL = 'SET_SHOW_SAMPLE_OPTIONS_MODAL';

// Copy Migrate Warning Modal Reducer
export const IS_COPY_MIGRATE_WARNING_MODAL_OPEN = 'IS_COPY_MIGRATE_WARNING_MODAL_OPEN';
export const IS_OPENED_COPIED_MIGRATED_MEP_MODAL_OPEN = 'IS_OPENED_COPIED_MIGRATED_MEP_MODAL_OPEN';
export const SET_COPY_MIGRATE_ACTION = 'SET_COPY_MIGRATE_ACTION';

// Confirm Artifact Delete Modal Reducer
export const SET_SHOW_CONFIRM_ARTIFACT_DELETE_MODAL = 'SET_SHOW_CONFIRM_ARTIFACT_DELETE_MODAL';
export const UPDATE_CONFIRM_DELETE_MODE = 'UPDATE_CONFIRM_DELETE_MODE';
export const UPDATE_CONFIRM_ARTIFACT_TO_DELETE = 'UPDATE_CONFIRM_ARTIFACT_TO_DELETE';
